<template>
  <div class="container">
    <p class="stock-report" v-if="quantity&&quantity_threshold>=quantity&&limited_stock">
      <span v-text="this.limited_stock_data.storefront_appearance.limited_stock_text.replace('{{product.quantity}}', this.quantity) "></span>
    </p>
    
    <form
      class="notify-me"
      v-if="!subscrib && (!sellable||!quantity) && out_stock && disabled_size.length"
      @submit.prevent="notify_me()">
      <p class="notify-me-para">{{this.back_in_stock_data.storefront_appearance.title_text}}</p>
      <div class="input">
        <label for="product-stock-notify-size-dropdown">
          Choose Size
        </label>
        <select name="size"
          id="product-stock-notify-size-dropdown"
          required
          v-model="selected_size"
          class="notify-select-box">
          <option v-for="(size,i) of disabled_size" :key="i" :value="size.value">{{size.display}}</option>
        </select>
      </div>
      <div class="input" v-if="!loggedInEmail">
        <label for="product-stock-notify-email-input">
          Your Email
        </label>
        <input type="email"
          name="product-stock-notify-email-input"
          id="product-stock-notify-email-input"
          required
          v-model.trim="email"
          :placeholder="this.back_in_stock_data.storefront_appearance.placeholder"
        />
      </div>
      <div class="button">
        <button type="submit"
          class="n-button color ripple n-button-secondary n-flat-button n-flat-button-secondary"
          id="subscribe"
          :style="'background:'+this.back_in_stock_data.storefront_appearance.button_color"
        >
          {{this.back_in_stock_data.storefront_appearance.button_text}}
        </button>
      </div>
      <p>
        We respect your privacy & do not share your email with anyone.
      </p>
    </form>
    <p v-if="already_subscrib && !sellable && out_stock"
      class="success-message">
      {{this.already_subscrib_msg}}
    </p>
    <p v-if="success&&out_stock"
      class="success-message">
      {{this.back_in_stock_data.storefront_appearance.message_text}}
    </p>
    <p v-if="!subscrib && disabled_size.length &&sellable && out_stock"
      class="size-not-avail">
      Size not available ? 
      <span @click="sellable=false">
        Notify me
      </span>
    </p>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        already_subscrib:false,
        success:false,
        limited_stock:true,
        out_stock:true,
        quantity:null,
        quantity_threshold:5,
        sellable:true,
        email:[],
        /**
         * @typedef { string }
         */
        loggedInEmail: '',
        subscrib:false,
        selected_size:'',
        already_subscrib_msg:'Already subscribed for notify',
        disabled_size:[],
        back_in_stock_data: {
          storefront_appearance: {
            title_text: "Notify me when the item gets back in stock!",
            placeholder: "Enter your email",
            custom_text: "",
            button_text: "Notify Me",
            button_color: "#F2994A",
            message_text: "Thank you! We will notify you on the email you have registered once the item is back in stock.",
          },
        },
        limited_stock_data: {
          storefront_appearance: {
            limited_stock_text:"Only {{product.quantity}} left in stock! Order soon.",
            custom_text: "",
          },
        },
        base_url : `${window.location.origin}/ext/prodStockNotify/v1/v1.0`,
      }
    },
    async mounted(){
      this.getAppConfig();
      await this.getBackinConfig();
      this.getLimitedConfig();
      window.FPI.state.user.subscribe(this.checkLoggedInUser);
      if(window.FPI.state.product){
        setTimeout(async () => {
          this.sellable=window.FPI.state.product.meta._data.sellable;
          this.disabled_size = this.size_filter(window.FPI.state.product.meta._data?.sizes || []);
          if(this.disabled_size.length){
            this.selected_size=this.disabled_size[0].value;
          }
          else
          {
            const out_stock_size_filter = this.out_stock_size_filter(window.FPI.state.product.meta._data.sizes);
            for (let i = 0; i < out_stock_size_filter.length; i++) {
              let getdata = JSON.parse(localStorage.getItem('product_stock_notify_ext_'+window.FPI.state.product._data.product.uid+'_'+out_stock_size_filter[0].value));
              if(getdata?.value?.item_id==window.FPI.state.product._data.product.uid){
                this.subscrib=true;
                this.already_subscrib=true;
              }
            }
          }
        }, 2000);
        window.FPI.state.product.selectedArticle.subscribe((article) => {
          this.quantity=article?.quantity
        })
      }
    },
    methods: {
      /**
       * @param {{
       * user: {
       *  account_type: "user",
       *  active: boolean,
       *  emails: string[],
       *  user_id: string,
       *  _id: string
       * }
       * }} userData
       */
      checkLoggedInUser(userData) {
        const user = userData?.user?.emails?.[0];
        if (!user) {
          return;
        }
        else if (typeof user === 'string') {
          this.loggedInEmail = user;
        } else {
        //Getting the primary Email
          const primaryEmail = userData?.user?.emails?.find(email => email.primary);
          this.loggedInEmail = primaryEmail ? primaryEmail.email.trim() : userData?.user?.emails[0].email.trim();
        }
      },
      size_filter(sizes){
        return sizes.filter(size => !size.is_available&&!localStorage.getItem('product_stock_notify_ext_'+window.FPI.state.product._data.product.uid+'_'+size.value))
      },
      out_stock_size_filter(sizes){
        return sizes.filter(size => !size.is_available)
      },
      getAppConfig(){
        axios({
          method: 'get',
          url: `${this.base_url}/sales_channels`,
        }).then(response => {
          if(response.data){
            this.quantity_threshold=response.data[0].limited_stock?.quantity_threshold || 0;
            this.limited_stock=response.data[0].limited_stock?.enabled;
            this.out_stock=response.data[0].out_stock.enabled;
          }
        })
      },
      getBackinConfig(){
        axios({
          method: 'get',
          url: `${this.base_url}/out_stock_config`,
        }).then(response => {
          if(response.data){
            this.back_in_stock_data.storefront_appearance=response.data.storefront_appearance
          }
        })
      },
      getLimitedConfig(){
        axios({
          method: 'get',
          url: `${this.base_url}/limited_stock_config`,
        }).then(response => {
          if(response.data){
            this.limited_stock_data.storefront_appearance=response.data.storefront_appearance
          }
        });
      },

      /**
       * Meant to be called inside `notify_me()` method
       * In some sales channels `window.FPI.state.user._data.user.emails[0]` is string and in some it is an object
       * @returns {string} email address of the user either logged in or entered email input
       */
      getUserEmail() {
        let email;
        if(this.loggedInEmail){
          email = this.loggedInEmail;
        } else {
          email = this.email;
        }
        return email;
      },

      notify_me(){
        let email = this.getUserEmail();
        const data = {
          email: [{email}],
          media:window.FPI.state.product._data.product.medias,
          name:window.FPI.state.product._data.product.name,
          description:window.FPI.state.product._data.product.description,
          item_id:window.FPI.state.product._data.product.uid,
          size:this.selected_size,
          article_id:window.FPI.state.product.selectedArticle?._data?.article_id,
          product_uid:window.FPI.state.product.selectedArticle?._data?.store?.uid,
        }

        axios({
          method: 'post',
          url: `${this.base_url}/out_stock_email`,
          data
        }).then(response => {
          this.success=true;
          this.subscrib=true;
          const now = new Date();
          const item = {
              value: response.data,
              expiry: now.getTime() + 648000000
          };
          localStorage.setItem('product_stock_notify_ext_'+window.FPI.state.product._data.product.uid+'_'+this.selected_size, JSON.stringify(item));
        })
      }
    },
    destroyed() {
      window.FPI.state.user.unsubscribe(this.checkLoggedInUser);
    }
  }
</script>  
<style scoped>
.success-message
{
  padding: 10px 16px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  line-height: 17px;
  word-break: break-word;
}
.notify-select-box{
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  appearance: auto !important;
}
.size-not-avail
{
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
  color:#41434C;
  word-break: break-word;
}
.size-not-avail span
{
  color: #EB5757;
  cursor: pointer;
}
.container {
  width: 100%;
}
.notify-me {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.notify-me-para {
  font-weight: 600;
  color: #4f4f4f;
  margin-bottom:15px;
  word-break: break-word;
}
.input {
  margin: 0px 0px 15px 0px;
  width: 100%;
  float: left;
}
.input input , .input select {
  box-sizing: border-box;
  font-size: 14px;
  appearance: none;
  height: 40px;
  color: rgb(65, 67, 76);
  width: 100%;
  padding: 0px 12px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  border-image: initial;
  border-radius: 3px;
  background: rgb(255, 255, 255);
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button{
  margin-bottom: 5px;
  width: 100%;
}
.color.disabled
{
  opacity: .5 !important;
}
.input label {
  font-style: normal;
  font-weight: 400;
  color: #4f4f4f;
  font-size: 12px;
  margin-bottom: 5px;
  display: block;
}
.input label::after {
  content: '*';
}

.color {
  margin-bottom: 10px;
  background: #f2994a;
  color: rgb(255, 255, 255);
  padding: 0px 30px;
  border: none;
  border-radius: 3px;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  height: 39px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}
.stock-report {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #eb5757;
  margin-bottom: 10px;
  word-break: break-word;
}
</style>
