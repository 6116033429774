// @ts-ignore
import App from './components/App.vue'

// @ts-ignore
window.FPI.extension.register("#ext_product_stock_notification", {
  mounted(element) {
    // @ts-ignore
    window.FPI.extension.mountApp({
      element, 
      component: App,
    });
  }
})

/**
 * Code below is commented purposefully.
 * Uncomment and use this code if webhooks are too flaky.
 * also check for the event `order.processed` if `order.checkout` did not work
 */
// window.FPI.event.on('order.checkout', function(eventData) {
//   // @ts-check
//   // @ts-ignore
//   axios({
//     method: 'post',
//     url: `${window.location.origin}/ext/new_notify_proxy/v1/v1.0/analytics/order_placed`,
//     eventData
//   }).then(function(response) {
//     /**
//      * @todo remove console logging
//      */
//     console.log(response)
//   });
// });
