<template>
    <div>
        <Notify_me_form/>
    </div>
</template>

<script>
import Notify_me_form from './Notify_me_form.vue'
export default {
    name: 'notify_me',
    components: {
        Notify_me_form
    }
}
</script>
